.header {
  display: flex; /* Utilizamos flexbox para centrar la imagen */
  justify-content: center; /* Centramos horizontalmente */
  align-items: center; /* Centramos verticalmente */
  margin-bottom: 20px; /* Espacio inferior para separar la cabecera del resto del contenido */
}

.logo {
  width: 250px; /* Tamaño de la imagen */
  height: auto; /* Altura automática para mantener la proporción */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%; 
  justify-content: space-between;
  align-items: center;  
  background-color: #f0f0f0; /* Fondo para el resto de la página */
  padding: 0px;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 600px; /* Puedes ajustar el ancho máximo según tus necesidades */
  padding: 0px;
  box-sizing: border-box;
}


.ticket {
  background-color: #ffffff; /* Fondo blanco para la tarjeta */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 500px;
  text-align: center;
  border: 1px solid #ddd;
  margin: 20px;
}

.ticket h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.ticket h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

.ticket hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
}

.justified-text {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.left-text {
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.ticket-info {
  font-size: 18px;
  margin-bottom: 20px;
  color: #444;
}

.ticket-info strong {
  color: #000;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus {
  border-color: #007bff;
  outline: none;
}

button {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.modal h2 {
  margin-top: 0;
}

.modal button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal button:hover {
  background: #0056b3;
}

.modal-error {
  color: red;
}

.footer {
  text-align: center;
  width: 100%;
  padding: 10px 0;
}

/* Media Queries para ajustar el diseño en dispositivos móviles */
@media (max-width: 768px) {
  .ticket {
    width: 90%; /* Aseguramos que ocupe el 90% del ancho de la pantalla en móviles */
    padding: 20px;
  }

  .logo {
    width: 90%; /* Ajustamos el ancho del logo en dispositivos móviles */
  }
}

/* ModalMessage.css */
.highlight-text {
  font-weight: bold; /* Puedes ajustar el estilo según tus preferencias, como cambiar el color o el tamaño del texto */
  color: red; /* Ejemplo: color rojo */
}